import { BASE_ZINDEX } from "../../../popup-factory";
import { Report } from "../action-menu/types";

export const ExportedReportPopupBaseProps = {
  style: {
    height: "auto",
    borderRadius: "10px",
  },
  zIndex: BASE_ZINDEX + 201,
  overlayZIndex: BASE_ZINDEX + 101,
};

export type ExportedReport = {
  exported_report_id: string;
  report_id: string;
  scheduled_report_id: string;
  created_by: string;
  date_created: string;
  active: boolean;
  report: Report;
};

export interface ExportedReportPopupProps {
  exported_report_id: string;
}

export interface PopupHeaderProps {
  title: React.ReactNode;
  onPopupClose: () => void;
}

export interface ExportedReportsHookProps {
  exported_report_id: string;
}
