import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
import {
  getExportedReportById as getExportedReportByIdAction,
  getExportedReport,
  selectLoading,
  selectError,
} from "../../../redux/exportedReports";
import { ExportedReportsHookProps } from "./types";

export function useExportedReports(props: ExportedReportsHookProps) {
  const { exported_report_id } = props;
  const dispatch = useDispatch();

  const exportedReport = useSelector(getExportedReport);

  const loading = useSelector(selectLoading);
  const error = useSelector(selectError);

  const operations = useMemo(
    () => ({
      getExportedReportById: async (exported_report_id: string) =>
        dispatch(getExportedReportByIdAction(exported_report_id)),
    }),
    [dispatch]
  );

  useEffect(() => {
    dispatch(getExportedReportByIdAction(exported_report_id));
  }, [dispatch]);

  return {
    state: { exportedReport, loading, error },
    operations,
  };
}
