
import { oauth } from "../utils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { window } from "../global";
import { ExportedReport } from "../components/report/export/types";

interface GetExportedReportByIdResponse {
  json: {
    exported_report: ExportedReport;
  };
  response: Response;
}

export interface ExportedReportState {
  loading: boolean;
  exported_report: ExportedReport;
  error?: string;
}

const initialState: ExportedReportState = {
  loading: false,
  // @ts-ignore
  exported_report: window?.initialState?.exported_report || [],
  error: undefined,
};

const exportedReportsSlice = createSlice({
  name: "exportedReports",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setExportedReport(state, action: PayloadAction<ExportedReport>) {
      state.exported_report = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.error = action.payload;
    },
  },
});

export const { setLoading, setExportedReport, setError } =
  exportedReportsSlice.actions;

export const getExportedReport = (state): ExportedReport =>
  state.exportedReports.exported_report;

export const getExportedReportById =
  (exported_report_id: string) => async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response: GetExportedReportByIdResponse = await oauth(
        "GET",
        `exported-report/${exported_report_id}`,
        {}
      );

      if (!response.json) {
        dispatch(setError("Unable to fetch report"));
        return;
      }

      dispatch(setExportedReport(response.json.exported_report));
    } catch (error) {
      dispatch(setError(error.toString()));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const selectLoading = (state) => state.exportedReports.loading;

export const selectError = (state) => state.exportedReports.error;

export default exportedReportsSlice.reducer;
