import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  AlertNotification,
  colors,
  DownloadIcon,
  IconButton,
  Link,
  Popup,
  PopupHeader,
  SkubotSpinner,
  Text,
  Typography,
  XIcon,
} from "@commonsku/styles";
import { closePopup } from "../../../actions/popup";
import styled from "styled-components";
import moment from "moment";
import { useExportedReports } from "./useExportedReports";
import exportedReportsReducer from "../../../redux/exportedReports";
import withReducers from "../../../store/withReducers";
import { useHasCapabilities } from "../../../hooks";
import useMobileScreen from "../../../hooks/useMobileScreen";
import { mobileScreenSize } from "../../../utils";
import { ExportedReportPopupBaseProps, ExportedReportPopupProps, PopupHeaderProps } from "./types";


const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media (max-width: ${mobileScreenSize}px) {
    flex-direction: column;
  }
`;

const ReportDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.neutrals[100]};
  font-weight: 400;

  .title {
    font-weight: 700;
    text-align: center;
  }

  .details {
    margin-bottom: 0;
  }
`;

const StyledPopupHeader = styled(PopupHeader)`
  font-size: 24px;
  font-weight: 400;
  line-height: 40px;
  color: ${colors.neutrals["90"]};
`;

const ErrorMessageContainer = styled.div`
  display: flex;
  gap: 16px;
  alignitems: flex-start;
  alignself: stretch;
  flex-direction: row;

  .error-message {
    flex: 8;
    margin-bottom: 0;
  }

  .link {
    color: ${colors.primary2};
    flex: 2;
  }
  @media (max-width: ${mobileScreenSize}px) {
    flex-direction: column;
  }
`;

const PopupHeaderWithCloseIcon = ({
  title,
  onPopupClose,
}: PopupHeaderProps) => (
  <StyledPopupHeader>
    <Text>{title}</Text>
    <IconButton Icon={XIcon} variant="text" onClick={onPopupClose} />
  </StyledPopupHeader>
);

const LoadingPopup = ({ mobile }: { mobile: boolean }) => (
  <StyledContainer>
    <Typography>Your report download will start in few minutes.</Typography>
    <SkubotSpinner size={mobile ? "medium" : "default"} />
  </StyledContainer>
);

const ErrorPopup = ({ unauthorized }: { unauthorized: boolean }) => (
  <AlertNotification
    alertType="error"
    href=""
    style={{ width: "100%", padding: "16px", height: "90%" }}
  >
    {unauthorized ? (
      <ErrorMessageContainer>
        <Typography className="error-message" color={colors.errors[80]}>
          You do not have the permissions required to view this report. Please
          check with your commonsku admin.
        </Typography>
        <Link
          className="link"
          as={"a"}
          href={
            "https://help.commonsku.com/knowledge/commonsku-user-permissions"
          }
          target={"_blank"}
        >
          Learn More
        </Link>
      </ErrorMessageContainer>
    ) : (
      <Typography style={{ marginBottom: "0px" }} color={colors.errors[80]}>
        The report does not exist.
      </Typography>
    )}
  </AlertNotification>
);

const ExportedReportPopup = ({exported_report_id}: ExportedReportPopupProps) => {
  const dispatch = useDispatch();
  const hasExportReport = useHasCapabilities("EXPORT-REPORT");
  const isMobile = useMobileScreen();

  const {
    state: { exportedReport, loading, error },
  } = useExportedReports({exported_report_id});

  const popupTitle = useMemo(() => {
    if (!hasExportReport) return "Download Report";
    return loading ? "Preparing Report..." : "Download Report";
  }, [loading, hasExportReport]);

  const popupStyles = useMemo(() => {
    return {
      ...ExportedReportPopupBaseProps.style,
      width: isMobile ? "80vw" : "50vw",
      minWidth: isMobile ? "250px" : "450px",
    };
  }, [isMobile]);

  const onPopupClose = () => dispatch(closePopup());

  const handleDownloadClick = () => {
    // TODO: CORE-1107 Add functionality to download file from S3. Currently no endpoint exists.
    onPopupClose();
  };

  if (!hasExportReport || error) {
    return (
      <Popup
        header={
          <PopupHeaderWithCloseIcon
            title={popupTitle}
            onPopupClose={onPopupClose}
          />
        }
        onClose={onPopupClose}
        {...ExportedReportPopupBaseProps}
        style={popupStyles}
      >
        <ErrorPopup unauthorized={!hasExportReport} />
      </Popup>
    );
  }

  return (
    <Popup
      {...ExportedReportPopupBaseProps}
      style={{ ...popupStyles, height: "55vh" }}
      header={
        <PopupHeaderWithCloseIcon
          title={popupTitle}
          onPopupClose={onPopupClose}
        />
      }
      onClose={onPopupClose}
    >
      {loading ? (
        <LoadingPopup mobile={isMobile} />
      ) : (
        <StyledContainer>
          <ReportDetailsContainer>
            <Text className="title">{exportedReport?.report?.report_name}</Text>
            <Typography className="details">
              {exportedReport?.report?.report_type_title}
            </Typography>
            <Typography className="details">
              {moment(exportedReport?.report?.date_created).format(
                "MMM DD, YYYY"
              )}
            </Typography>
          </ReportDetailsContainer>
          <IconButton
            Icon={DownloadIcon}
            variant="primary"
            onClick={handleDownloadClick}
            size="huge"
          >
            Download
          </IconButton>
        </StyledContainer>
      )}
    </Popup>
  );
};

export default withReducers(ExportedReportPopup, {
  exportedReports: exportedReportsReducer,
});
